// eslint-disable-next-line import/no-extraneous-dependencies
import * as Turbo from '@hotwired/turbo';
import Controller from '~/controllers/application_controller';

// Connects to data-controller="redirect"
export default class extends Controller {
  static values = {
    url: String,
  };

  connect() {
    Turbo.visit(this.urlValue);
  }
}
