import Controller from '~/controllers/application_controller';

// Connects to data-controller="redirect"
export default class extends Controller {
  static targets = ['sub'];

  publish({ detail, params }) {
    if (!detail) return;

    const value = detail[params?.key || 'value'];

    this.subTargets.forEach((element) => {
      const toEdit = element.getAttribute('data-attribute-edit') || 'value';
      element.setAttribute(toEdit, value);
    });
  }
}
