import Controller from '~/controllers/application_controller';

export default class extends Controller {
  static targets = ['input'];

  static values = {
    min: { type: Number, default: 0 },
    max: { type: Number, default: 1000 },
  };

  decrease() {
    this.inputTarget.value = Math.max(
      this.minValue,
      parseInt(this.inputTarget.value, 10) - 1,
    );
    this.#publish();
  }

  increase() {
    this.inputTarget.value = Math.min(
      this.maxValue,
      parseInt(this.inputTarget.value, 10) + 1,
    );
    this.#publish();
  }

  setValue() {
    this.#publish();
  }

  #publish() {
    const currVal = this.inputTarget.value;
    this.publishEvent('change', { value: currVal });
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.publishEvent('change-delay', { value: currVal });
    }, 500);
  }
}
