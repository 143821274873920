import Controller from '~/controllers/application_controller';

// Connects to data-controller="redirect"
export default class extends Controller {
  static values = {
    remove: String,
    target: String,
  };

  connect() {
    const element = document.getElementById(this.targetValue);
    element.classList.remove(this.removeValue);
  }
}
