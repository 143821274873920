import Controller from '~/controllers/application_controller';

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ['modalBackdrop', 'modal'];

  connect() {
    this.open();
  }

  open() {
    this.modalBackdropTarget.classList.remove('hidden');
    document.body.classList.add('overflow-hidden');
  }

  closeBackdrop(e) {
    if (e && this.modalTarget.contains(e.target)) return;
    this.#close();
  }

  closeButton() {
    this.#close();
  }

  #close() {
    this.modalBackdropTarget.classList.add('hidden');
    document.body.classList.remove('overflow-hidden');
  }
}
